// Main Inicializado!
(function($, scope_maximus){

	var lastItem = "home";
	
	this.MainClass = {
		

		// Document Ready Scope crpmango
		ready: function(){
			$(document).trigger('crpmango.ready');
		},
		// Document OnLoad Scope crpmango
		load: function(){
			$(document).trigger('crpmango.load');
		},
		// Init Scope crpmango
		init: function(){
			$(document).trigger('crpmango.init');

			// Inicializando as funções comuns a todas as páginas
			this.header();
			this.common();
			this.footer();
		},

		// Function Header
		// Funções JS presentes no Header
		header: function(){
		},

		// Function Footer
		// Funções JS presentes no Footer
		footer: function(){
		},

		// Function Common
		// Inserir aqui o js comum a todas as páginas!
		common: function(){

			// Função para inicializar Funções em páginas específicas!
			var _page = jQuery('body').attr('class'), _page = _page.split(' ');
			if(_page.length){
				_page.map(function(e,i){
					if(typeof scope_maximus.pages[e] == 'function'){
						scope_maximus.pages[e].call(scope_maximus);
					}
				});
			}			
		},

        isInViewport: function(element){
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
				(rect.bottom - (window.innerHeight - 500)) <= window.innerHeight &&
                rect.left >= 0 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },

		// Funções de páginas específicas
		// Inserir aqui função para cada página que tenha recursos específicos!
		pages: {

			'landing-maximus': function(){

                // Slide Cases
				$(".cases-navigate-btn").on('click', function(){
					var goto = $(this).find('.cases-navigate-item').data('target');
					goto = document.getElementById(goto);
					goto.scrollIntoView({
						behavior: 'smooth'
					});

                    $(".cases-navigate-btn").removeClass("cases-navigate-btn-active");
                    $(this).addClass("cases-navigate-btn-active");
                    
				});

				$(".arrow-down").on('click', function(){
					var goto = document.getElementById('case-1');
					goto.scrollIntoView({
						behavior: 'smooth'
					});
				});

                $(window).scroll(function () {

                    $('.background-verify-scroll').each(function(i, el){

                        if(scope_maximus.isInViewport(el)) {
                            
                            idCurrent = jQuery(this).attr("id");
							lastItem = idCurrent;
                            jQuery(".cases-navigate-btn-active").removeClass("cases-navigate-btn-active");
                            jQuery(".cases-navigate-btn").find(`[data-target='${lastItem}']`).parent().addClass("cases-navigate-btn-active");
						
							if(lastItem == 'contato'){
								jQuery(".btn-contato").fadeOut();
							}else{
								if(window.innerWidth > 991){
									jQuery(".btn-contato").fadeIn();
								}
							}
                        }
                    })

                });

				$('.background-verify-scroll').each(function(i, el){

					if(scope_maximus.isInViewport(el)) {
						
						idCurrent = jQuery(this).attr("id");
						
						lastItem = idCurrent;

						jQuery(".cases-navigate-btn-active").removeClass("cases-navigate-btn-active");
						jQuery(".cases-navigate-btn").find(`[data-target='${lastItem}']`).parent().addClass("cases-navigate-btn-active");
					}
				})

				jQuery(".icon-menu").on("click", function(){
					
                    jQuery("#menu li").removeClass("active");

					$('.background-verify-scroll').each(function(i, el){

						if(scope_maximus.isInViewport(el)) {
                            
                            idCurrent = jQuery(this).attr("id");

							lastItem = idCurrent;
                        }
                    })
			
					jQuery("#menu li").find(`[data-target='${lastItem }']`).parent().addClass("active");

					jQuery("#menu-open").fadeIn();
				})

				jQuery(".icon-close-menu, #menu li a").on("click", function(){
					jQuery("#menu-open").fadeOut();
				})

				//Slider de produtos
				jQuery("#slider-products").slick({
					dots: true,
					infinite: true,
					speed: 800,
					slidesToShow: 1,
					fade: true
				});

				$('.slide-prev').click(function(e){
					$('#slider-products').slick('slickPrev');
				});
				
				$('.slide-next').click(function(e){
					$('#slider-products').slick('slickNext');
				});

				//Alterar banner fundo de produtos
				jQuery('#slider-products').on('beforeChange', function(event, slick, currentSlide, nextSlide){
					switch(nextSlide) {
						case 0:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_equisolo.jpg"});
						  break;
						case 1:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_seed_turbo_plus.jpg"});
							break;
						case 2:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_turbo_organ_pro.jpg"});
							break;
						case 3:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_ultra.jpg"});
							break;
						case 4:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_vacin.jpg"});
							break;
						case 5:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_adjuvante.jpg"});
							break;
						default:
							jQuery("#produtos .background-case-image").css({"background-image": "url('./assets/img/background_equisolo.jpg"});
					}
				});

				// $('.your-element').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				// 	console.log(nextSlide);
				// });

			},
		},

	};


	// Criação e inicialização do escopo da função principal
	scope_maximus = this.MainClass;
	scope_maximus.init();
	window.crpmango_maximus = scope_maximus;

	// Scope.Ready and Scope.Load
	$(document).ready(function(){ scope_maximus.ready(); });
	$(window).on('load',function(){ scope_maximus.load(); });
})(jQuery, {});